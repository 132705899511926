import type { IntervalStats } from "store/member-stats/member-stats.model";
import { DAY_IN_MS, M_IN_KM, MPS_TO_KMPH } from "../analytic.page";
import {
  BallSpeedZone,
  RunSpeedZone,
} from "store/member-stats/member-stats.model";
import { DateTime, Duration } from "luxon";

export function getPlayerChartData(stats: IntervalStats) {
  const intervalItems = stats.data;
  const interval = intervalItems.length > 0 ? intervalItems[0].interval : 0;

  const spreadMS =
    intervalItems.length > 0
      ? intervalItems[intervalItems.length - 1].intervalStart -
        intervalItems[0].intervalStart
      : 0;
  const daysInclude = DAY_IN_MS < spreadMS;

  const distItems = intervalItems.map(
    (element) => element.playerMotion.totalDistance / M_IN_KM,
  );
  const kickItems = intervalItems.map((element) => element.kicks.count);

  const avgSpeedItems = intervalItems.map(
    (element) => element.playerMotion.avgRunningSpeed * MPS_TO_KMPH,
  );
  const peakSpeedItems = intervalItems.map(
    (element) => element.playerMotion.topRunningSpeed * MPS_TO_KMPH,
  );

  const ballZones = {
    [BallSpeedZone.COLD]: 0,
    [BallSpeedZone.MEDIUM]: 0,
    [BallSpeedZone.HOT]: 0,
    [BallSpeedZone.FIRE]: 0,
  };
  const avgRunZones: Record<string, number> = {
    [RunSpeedZone.WALK]: 0,
    [RunSpeedZone.JOG]: 0,
    [RunSpeedZone.RUN]: 0,
    [RunSpeedZone.HIGH_SPEED_RUN]: 0,
    [RunSpeedZone.SPRINT]: 0,
  };
  const peakRunZones: Record<string, number> = {
    [RunSpeedZone.WALK]: 0,
    [RunSpeedZone.JOG]: 0,
    [RunSpeedZone.RUN]: 0,
    [RunSpeedZone.HIGH_SPEED_RUN]: 0,
    [RunSpeedZone.SPRINT]: 0,
  };

  intervalItems.forEach((element) => {
    element.kicks.zones.forEach((zone) => {
      ballZones[zone.zone] += zone.count;
    });
  });

  intervalItems.forEach((element) => {
    element.playerMotion.avgSpeedZones.forEach((zone) => {
      avgRunZones[zone.zone] += zone.zoneDist / M_IN_KM;
    });
  });

  intervalItems.forEach((element) => {
    element.playerMotion.peakSpeedZones.forEach((zone) => {
      peakRunZones[zone.zone] += zone.zoneDist / M_IN_KM;
    });
  });

  const labels = intervalItems
    .map((element, index) => {
      const date = new Date(element.intervalStart + interval / 2);

      return {
        intervalStart: element.intervalStart,
        intervalIndex: index,
        time: date.getTime(),
        str: `${date.toLocaleTimeString()} ${
          daysInclude || index === 0 ? date.toLocaleDateString() : ""
        }`,
      };
    })
    .sort((a, b) => a.time - b.time);

  return {
    distItems,
    kickItems,
    avgSpeedItems,
    peakSpeedItems,
    ballZones,
    avgRunZones,
    peakRunZones,
    labels,
  };
};

export const getQueryInterval = (from: number, to: number) => (to - from) >  Duration.fromObject({ days: 14 }).as("milliseconds") ? "1w" : "1d";

const getRoundedDate = (minutes:number, dateToRound: Date) => {

  const ms = 1000 * 60 * minutes; // convert minutes to ms
  return new Date(Math.round(dateToRound.getTime() / ms) * ms);
};

export const getDefaultDataSet = (minsDataSet: boolean, from: number, to: number) =>
  minsDataSet ? getDefaultMinsDataSet(from, to) :  getDefaultDaysWeeksDataSet(from, to);

export const getDefaultMinsDataSet = (from: number, to: number) => {
  const fromRounded = getRoundedDate(5, new Date(from));
  const toRounded = getRoundedDate(5, new Date(to));

  const FIXED_TICKS = 120; 
  const fixedInterval = (to - from) / FIXED_TICKS;
  const xLabels = new Array<number>();
  // for(let i = 0; i < FIXED_TICKS; i++) {
  //   xLabels.push(from + i * fixedInterval);
  // }

  let interval = fromRounded.getTime();
  const fiveminInterval = 1000 * 60 * 5;
  while(interval <= toRounded.getTime()) {
    xLabels.push(interval);
    interval += fiveminInterval;
  }

  const addDayVal = DAY_IN_MS < (to - from);
  const dataset = new Array<any>();
  // setting default x lables for ticks
  xLabels.forEach(x => {
    const date = DateTime.fromMillis(x);
    const xLabel = addDayVal ? date.toFormat("HH:mm dd/MM/yyyy") : date.toFormat("HH:mm");
    dataset.push({ xTime: x, xLabel, xDate: date });
  });

  return dataset;
};

export const getDefaultDaysWeeksDataSet = (from: number, to: number) => {
  const WEEK_DURATION = Duration.fromObject({days: 7}).as("milliseconds"); 
  const DAY_DURATION = Duration.fromObject({days: 1}).as("milliseconds");
  
  const isDayInterval = getQueryInterval(from, to) === "1d";
  
  const fromDayStart = DateTime.fromMillis(from).set({ hour: 0, minute: 0, second: 0, millisecond: 0}).toMillis();
  const xLabels = new Array<number>();
  let xlabel = fromDayStart;
  while(xlabel < to) {
    xLabels.push(xlabel);
    xlabel += (isDayInterval ? DAY_DURATION : WEEK_DURATION);
  }

  const dataset = new Array<any>();
  // setting default x lables for ticks
  xLabels.forEach(x => {
    const date = DateTime.fromMillis(x);
    const nextWeekStart = DateTime.fromMillis(x + WEEK_DURATION - DAY_DURATION + 1);
    const xLabel = isDayInterval 
      ? date.toFormat("dd.MM.yy")
      : `${date.toFormat("dd.MM")}-${nextWeekStart.toFormat("dd.MM.yy")}`;
    dataset.push({ xTime: x, xLabel, xDate: date });
  });

  return dataset;
};

export type DataSetRecord = {
  xTime: number;
  xLabel: string;
  xDate: DateTime;
  expl?: number;
  sprint?: number;
  kickSpeed?: number;
  kickCount?: number;
  motionDist?: number;
  motionAvgSpeed?: number;
  motionPeakSpeed?: number;
  
  expl1?: number;
  sprint1?: number;
  kickSpeed1?: number;
  kickCount1?: number;
  motionDist1?: number;
  motionAvgSpeed1?: number;
  motionPeakSpeed1?: number;

  expl2?: number;
  sprint2?: number;
  kickSpeed2?: number;
  kickCount2?: number;
  motionDist2?: number;
  motionAvgSpeed2?: number;
  motionPeakSpeed2?: number;
  
  expl3?: number;
  sprint3?: number;
  kickSpeed3?: number;
  kickCount3?: number;
  motionDist3?: number;
  motionAvgSpeed3?: number;
  motionPeakSpeed3?: number;
};
