import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { AxisConfig, BarChart, BarSeriesType, ChartsXAxisProps } from "@mui/x-charts";
import { ChartInterval, StatDef } from "./chart-types";

export interface AtomicMetricsBarsProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval; // minute by default
  barColor?: string;
  measure: string;
}

export const AtomicMetricsBars = (props: AtomicMetricsBarsProps) => {
  
  const series = new Array<BarSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "bar",
      dataKey: stat.dataKey,
      label: stat.label,
      color: props.barColor ?? ActiveChartColors[props.dataKeys.indexOf(stat)]
    });
  });

  return <BarChart
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    xAxis = {[
      { scaleType: "band", id: "x-axis-id", disableTicks: true, dataKey: "xLabel",
        categoryGapRatio: 0.8,
        barGapRatio: 0.1,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval),
        valueFormatter: axisXTickFormat, tickLabelPlacement: "middle"
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    grid={{ horizontal: true }}
    axisHighlight={{ x: "band" }}
    tooltip={{trigger:"axis"}}
    dataset={props.dataSet}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true, label: props.measure }
    ]}
    slotProps={{ legend: {
      position: { vertical: "bottom", horizontal: "middle" },
      hidden: series.length < 2
    }, bar: { width: "8px" } }}
  >
  </BarChart>;
};
