import { useState, useEffect, useRef, useContext } from "react";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  TextField,
  Stack,
  Icon,
} from "@mui/material";
import { useAuthMutation, useAuthQuery } from "providers/auth";
import { toast } from "react-toastify";
import { getUserProfile, updateUserProfile } from "store/user/user.query";
import { FileUploadComponent } from "components/file-upload.component";
import { PositionButton } from "containers/position/position.button";
import { PositionSelectModal } from "containers/position/position-select.modal";
import { PlayerPosition } from "@gamer/common/lib/models/user";
import { createMedia, uploadMedia } from "store/media/media.query";
import { MediaType } from "store/media/media.model";
import { useMutation } from "react-query";
import { Trans } from "@lingui/macro";
import { dynamicActivate } from "utils/i18n";
import { countryList } from "containers/layout/country-selector.container";
import { NavigationContext } from "providers/navigation.provider";
import { validateName } from "store/user";

export const UserProfileView = () => {
  const { selectedLocale, setSelectedLocale } = useContext(NavigationContext);
  const userQuery = useAuthQuery("userProfile", getUserProfile);
  const [avatarKey, setAvatarKey] = useState<string | null>("");
  const updateUserProfileMutation = useAuthMutation(
    [ avatarKey, "userProfileUpdate" ],
    updateUserProfile
  );

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [fnError, setFnError] = useState("");
  const [lnError, setLnError] = useState("");
  const [avatar, setAvatar] = useState("");
  const [selectPositionModal, setSelectPositionModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPos] = useState<PlayerPosition | undefined>(undefined);

  const createMediaPlaceQuery = useAuthMutation("createMediaUserAvatar", createMedia);
  const uploadMediaQuery = useMutation("uploadMedia", uploadMedia);
  const [avatarFile, setAvatarFile] = useState<File | undefined>();
  const [uploadUrl, setUploadUrl] = useState<string>("");
  const [uploadInProgress, setUploadInProgress] = useState(false);

  useEffect(() => {
    if (uploadUrl && avatarFile && !uploadInProgress) {
      uploadMediaQuery.mutate({ url: uploadUrl, body: avatarFile });
      setUploadInProgress(true);
    }
  }, [uploadUrl, avatarFile, uploadMediaQuery, uploadInProgress]);

  useEffect(() => {
    if (createMediaPlaceQuery.isSuccess) {
      setUploadUrl(createMediaPlaceQuery.data?.url || "");
      setAvatarKey(createMediaPlaceQuery.data?.key || "");
      setUploadInProgress(false);
    }
  }, [createMediaPlaceQuery.isSuccess, createMediaPlaceQuery.data]);

  useEffect(() => {
    const v8Fn = validateName(firstName);
    const v8Ln = validateName(lastName);

    setFnError(v8Fn);
    setLnError(v8Ln);
  }, [firstName, lastName]);

  useEffect(() => {
    if (userQuery.data) {
      const currentPos = userQuery.data.position || "";
      const isNamesErrors = fnError || lnError;
      const isNoChanges = !firstName && !lastName;
      const isTheSame =
        userQuery.data.firstName === firstName &&
        userQuery.data.lastName === lastName &&
        currentPos === position &&
        !avatarFile;

      if (isNamesErrors || isTheSame || isNoChanges) {
        return setSubmitEnabled(false);
      }

      if (avatarFile ? uploadMediaQuery.isSuccess : true) {
        return setSubmitEnabled(true);
      }
    }
  }, [
    firstName,
    lastName,
    position,
    fnError,
    lnError,
    avatarFile,
    userQuery,
    uploadMediaQuery.isSuccess,
  ]);

  useEffect(() => {
    if (userQuery.data) {
      if (!position) {
        setPos(userQuery.data.position || PlayerPosition.GK);
      }
      if (!firstName && !lastName) {
        setFirstName(userQuery.data.firstName || "");
        setLastName(userQuery.data.lastName || "");
      }
    }
  }, [userQuery, position, firstName, lastName]);

  const handleFileChange = async (file: File) => {
    if (file) {
      await createMediaPlaceQuery.mutateAsync({ type: MediaType.IMAGE });
      setUploadInProgress(false);
      setAvatarFile(file);
      setAvatar(URL.createObjectURL(file));
    }
  };

  const updatePlayerInfo = () => {
    updateUserProfileMutation.mutate({
      data: {
        firstName: firstName,
        lastName: lastName,
        position: position || undefined,
        avatar: avatarKey || undefined,
      },
    });
  };

  useEffect(() => {
    if (updateUserProfileMutation.isError) {
      toast.error(
        `Failed to update user data. ${
          updateUserProfileMutation.error?.data.detail || ""
        }`);
    } else if (updateUserProfileMutation.isSuccess) {
      toast.success("Profile info updated!");
      setAvatarKey(null);
      setSubmitEnabled(false);
      avatar && URL.revokeObjectURL(avatar);
    }
    userQuery.refetch();
  }, [ updateUserProfileMutation.isError, updateUserProfileMutation.isSuccess ]);

  return (
    <>
      <Grid container direction="column">
        <Grid item mb={2}>
          <Typography variant="h4">
            <Trans>USER PROFILE</Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="flex-end"
          >
            <Grid item xs={8}>
              <Grid container direction="column" rowSpacing={2} width={450}>
                <Grid item>
                  <TextField
                    variant="filled"
                    helperText={fnError}
                    error={!!fnError}
                    label={<Trans>First Name</Trans>}
                    value={firstName}
                    name="firstName"
                    fullWidth
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="filled"
                    label={<Trans>Last Name</Trans>}
                    name="lastName"
                    value={lastName}
                    helperText={lnError}
                    error={!!lnError}
                    fullWidth
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <InputLabel shrink id="position-label">
                    <Trans>Preferred Position</Trans>
                  </InputLabel>
                  <PositionButton
                    onClick={() => setSelectPositionModal(true)}
                    position={position}
                    width={450}
                  />
                </Grid>
                <Grid item sx={{ my: "20px" }}>
                  <FormControl
                    color={"primary"}
                    variant="outlined"
                    sx={{
                      maxWidth: "450px",
                      width: "100%",
                    }}
                  >
                    <InputLabel id="">
                      <Trans>Language</Trans>
                    </InputLabel>
                    <Select
                      label="Language"
                      variant="outlined"
                      value={selectedLocale}
                      color="primary"
                      sx={{ pt: "4px", borderRadius: "4px", height: 50 }}
                      onChange={(_e, v: any) => {
                        setSelectedLocale(v.props.value);
                        dynamicActivate(v.props.value, true);
                      }}
                    >
                      {Object.keys(countryList).map((k) => (
                        <MenuItem key={`locale-${k}`} value={k}>
                          <Stack direction="row">
                            <Icon sx={{ mr: "12px", fontSize: 20 }}>
                              {countryList[k].flag}
                            </Icon>
                            <Stack>
                              <Typography
                                variant="body2"
                                fontSize="14px"
                                whiteSpace="initial"
                                justifyContent="center"
                              >
                                {countryList[k].language}
                              </Typography>
                            </Stack>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      !submitEnabled || updateUserProfileMutation.isLoading
                    }
                    onClick={updatePlayerInfo}
                  >
                    {updateUserProfileMutation.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Trans>SAVE</Trans>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <FileUploadComponent handleFile={handleFileChange}>
                    <IconButton>
                      <Avatar
                        style={{ width: 200, height: 200 }}
                        src={avatar || userQuery.data?.avatar || ""}
                      ></Avatar>
                    </IconButton>
                  </FileUploadComponent>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    AIC ID: {userQuery.data?.aicId}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    PLAYER ID: {userQuery.data?.playerId}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    APP VERSION: {process.env.REACT_APP_VERSION}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    COMMIT ID: {process.env.REACT_APP_COMMIT_ID}
                  </Typography>
                </Grid>
                <Grid item style={{ height: "50px" }}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {selectPositionModal && (
        <PositionSelectModal
          open={selectPositionModal}
          handleClose={(position) => {
            setPos(position);
            setSelectPositionModal(false);
          }}
          position={position}
        />
      )}
    </>
  );
};
