import { Skeleton, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  BallSpeedZone,
  IntervalMetric,
  RunSpeedZone,
} from "store/member-stats/member-stats.model";
import { M_IN_KM } from "../analytic.page";
import { useAuthQuery } from "providers/auth";
import { getMemberStats } from "store/member-stats/member-stats.query";
import MetricsDistance from "components/icons/MetricsDistance";
import MetricsKickCount from "components/icons/MetricsKickCount";
import MetricsRunningSpeed from "components/icons/MetricsRunningSpeed";
import MetricsSprintDistance from "components/icons/MetricsSprintDistance";
import { MotionSpeedLines } from "components/charts/motion-speed-lines";
import { AtomicMetricsBars } from "components/charts/atomic-metrics-bars";
import { BarLineMixedStats } from "components/charts/bar-line-mixed-stats";
import { HeadlineTitle } from "../components/headline-title";
import { StatsVSStatsTitle } from "../components/stat-vs-stat-title";
import { StatTitle } from "../components/stat-title";
import MetricsExplosivenessDistance from "components/icons/MetricsExplosivenessDistance";
import MetricsBallSpeed from "components/icons/MetricsBallSpeed";
import { DataSetRecord, getDefaultDataSet, getQueryInterval } from "./helpers";
import { SpeedZoneHorizontalLineChart } from "components/charts/line-speedzone-line";
import { ChartInterval } from "components/charts/chart-types";
import { SpeedZoneData } from "components/charts/utils";

type PlayerStatsDaysWeeksProps = {
  memberId: string;
  teamId: string;
  from: number;
  to: number;
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};

export default function PlayerStatsDaysWeeks({
  memberId,
  from,
  to,
  teamId,
  onExport,
  setDisabledPlayerIds
}: PlayerStatsDaysWeeksProps) {

  const queryInterval = getQueryInterval(from, to);
  const isDayInterval = queryInterval === "1d";
  
  const memberStatsQuery = useAuthQuery(
    ["memberStats", teamId, memberId, from, to, queryInterval],
    getMemberStats({
      teamId,
      memberId,
      from,
      to,
      interval: queryInterval,
    }),
  );

  const [sprintDistSum, setSprintDistSum] = React.useState(0);
  const [sprintCnt, setSprintCnt] = React.useState(0);
  const [expDistSum, setExpDistSum] = React.useState(0);
  const [expCnt, setExpCnt] = React.useState(0);

  const [avgRunSpeedZones, setAvgRunSpeedZones] = React.useState(Array<SpeedZoneData>);
  const [peakRunSpeedZones, setPeakRunSpeedZones] = React.useState(Array<SpeedZoneData>);
  const [ballSpeedZones, setBallSpeedZones] = React.useState(Array<SpeedZoneData>);

  const [dataSet, setDateSet] = React.useState<Array<any>>([]);
  const [defDataSet, setDefDataSet] = React.useState<Array<DataSetRecord>>([]);

  
  React.useEffect(() => {
    setDefDataSet(getDefaultDataSet(false, from, to));   
  }, [from, to]);

  React.useEffect(() => {
    if (memberStatsQuery.data) {

      if (memberStatsQuery.data.data.length === 0) {
        setDisabledPlayerIds?.([memberId]);
      }

      const intervalItems = memberStatsQuery.data.data;

      const ballZones = {
        [BallSpeedZone.COLD]: 0,
        [BallSpeedZone.MEDIUM]: 0,
        [BallSpeedZone.HOT]: 0,
        [BallSpeedZone.FIRE]: 0,
      };
      intervalItems.forEach((element) => {
        element.kicks.zones.forEach((zone) => {
          ballZones[zone.zone] += zone.count;
        });
      });
      setBallSpeedZones(Object.keys(ballZones).map((zone) => ({ zone, value: ballZones[zone] } as SpeedZoneData)));

      const avgRunZones: Record<any, number> = {
        [RunSpeedZone.WALK]: 0,
        [RunSpeedZone.JOG]: 0,
        [RunSpeedZone.RUN]: 0,
        [RunSpeedZone.HIGH_SPEED_RUN]: 0,
        [RunSpeedZone.SPRINT]: 0,
      };
      intervalItems.forEach((element) => {
        element.playerMotion.avgSpeedZones.forEach((zone) => {
          avgRunZones[zone.zone] += zone.zoneDist;
        });
      });
      setAvgRunSpeedZones(Object.keys(avgRunZones).map((zone) => ({ zone, value: avgRunZones[zone] } as SpeedZoneData)));

      const peakRunZones: Record<any, number> = {
        [RunSpeedZone.WALK]: 0,
        [RunSpeedZone.JOG]: 0,
        [RunSpeedZone.RUN]: 0,
        [RunSpeedZone.HIGH_SPEED_RUN]: 0,
        [RunSpeedZone.SPRINT]: 0,
      };
      intervalItems.forEach((element) => {
        element.playerMotion.peakSpeedZones.forEach((zone) => {
          peakRunZones[zone.zone as RunSpeedZone] += zone.zoneDist;
        });
      });
      setPeakRunSpeedZones(Object.keys(peakRunZones).map((zone) => ({ zone, value: peakRunZones[zone] } as SpeedZoneData)));

      const dataset = defDataSet;
      intervalItems.forEach(item => {
        const index = dataset.findIndex(el => el.xTime >= item.intervalStart);
        const element = dataset.at(index > -1 ? index : dataset.length - 1);
        if (element) {
          element.motionDist = item.playerMotion.totalDistance + (element.motionDist || 0);
          if (element.motionDist === 0) {
            element.motionDist = undefined;
          }
          element.motionAvgSpeed = Math.max(item.playerMotion.avgRunningSpeed, (element.motionAvgSpeed || 0));
          if (element.motionAvgSpeed === 0) {
            element.motionAvgSpeed = undefined;
          }
          element.motionPeakSpeed = Math.max(item.playerMotion.topRunningSpeed, (element.motionPeakSpeed || 0));
          if (element.motionPeakSpeed === 0) {
            element.motionPeakSpeed = undefined;
          }
          element.kickCount = item.kicks.count + (element.kickCount || 0);
          if (element.kickCount === 0) {
            element.kickCount = undefined;
          }
          
          element.expl = item.explosiveness.totalDistance + (element.expl || 0);
          if (element.expl === 0) {
            element.expl = undefined;
          }

          element.sprint = item.sprint.totalDistance + (element.sprint || 0);
          if (element.sprint === 0) {
            element.sprint = undefined;
          }

          element.kickSpeed = Math.max(item.kicks.topBallSpeed, (element.kickSpeed || 0));
          if (element.kickSpeed === 0) {
            element.kickSpeed = undefined;
          }
        }
      });

      setExpCnt(intervalItems.map(item => item.explosiveness.count).reduce((prev, cur) => prev + cur, 0));
      setExpDistSum(intervalItems.map(item => item.explosiveness.totalDistance).reduce((prev, cur) => prev + cur, 0));
      setSprintCnt(intervalItems.map(item => item.sprint.count).reduce((prev, cur) => prev + cur, 0));
      setSprintDistSum(intervalItems.map(item => item.sprint.totalDistance).reduce((prev, cur) => prev + cur, 0));

      setDateSet(dataset);
    }
  }, [memberStatsQuery.isSuccess, memberStatsQuery.data, memberId, defDataSet]);

  React.useEffect(() => {
    if (memberStatsQuery.isSuccess) {
      onExport?.([memberStatsQuery.data.data]);
    }
  }, [memberStatsQuery.isSuccess]);

  return (
    <> 
      {memberStatsQuery.isLoading ? (
        <>
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
        </>
      ) : (
        <>
          <Stack direction="column" spacing={2} >
            <HeadlineTitle title="PLAYER" />
            { dataSet.length > 0 &&
              <>
                <Stack id="dist-vs-kicks" direction="column">
                  <StatsVSStatsTitle stat1Title="Distance" stat2Title="Kicks Count"
                    stat1Icon={<MetricsDistance width="16px" height="16px" style={{ fontSize: 32}} />}
                    stat2Icon={<MetricsKickCount width="16px" height="16px" style={{ fontSize: 32}} />} />
                  <BarLineMixedStats
                    chartInterval={isDayInterval ? ChartInterval.DAY : ChartInterval.WEEK}
                    dataSet={dataSet}
                    dataKeys={[
                      { chartType: "bar", dataKey: "kickCount", label: "Kicks Count", axisPos: "right"},
                      { chartType: "line", dataKey: "motionDist", label: "Distance", axisPos: "left"}
                    ]}/>
                </Stack>
                <Stack id="avg-vs-peak-speed" direction="column">
                  <StatsVSStatsTitle stat1Title="Average Speed" stat2Title="Peak Speed"
                    stat1Icon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32}} />}
                    stat2Icon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32}} />} />
                  <MotionSpeedLines
                    chartInterval={isDayInterval ? ChartInterval.DAY : ChartInterval.WEEK}
                    dataSet={dataSet}
                    dataKeys={[
                      { dataKey: "motionPeakSpeed", label: "Peak Speed"},
                      { dataKey: "motionAvgSpeed", label: "Avg Speed"}]
                    } />
                </Stack>
                <Stack id="runZones" direction="column">
                  <StatTitle statTitle="Run Speed Zones"
                    statIcon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32}} />}
                     />
                  <SpeedZoneHorizontalLineChart
                    title="Total distance"
                    data={peakRunSpeedZones}
                    units="meter"
                  />
                </Stack>
                <Stack id="sprint" direction="column">
                  <StatTitle statTitle="Sprint"
                    statIcon={<MetricsSprintDistance width="16px" height="16px" style={{ fontSize: 32}} />}
                    summary={<Stack id="sprintSummary" direction="row"
                      spacing={1} alignItems="flex-end"
                      justifyContent="flex-start" style={{ paddingLeft: "20px" }}>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>Total Distance:</Typography>
                        <Typography variant="h6" style={{ fontStyle: "italic", lineHeight: 1}}>{(sprintDistSum / 1000).toFixed(2) }</Typography>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>km</Typography>
                        <span style={{width: "20px"}}></span>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>Total Count:</Typography>
                        <Typography variant="h6" style={{ fontStyle: "italic", lineHeight: 1}}>{sprintCnt}</Typography>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>sprints</Typography>
                    </Stack>} />
                  <AtomicMetricsBars
                    chartInterval={isDayInterval ? ChartInterval.DAY : ChartInterval.WEEK}
                    dataSet={dataSet}
                    dataKeys={[ {dataKey: "sprint", label: "Sprint"} ]}
                    measure="M"
                   />
                </Stack>
                <Stack id="explosiveness" direction="column">
                  <StatTitle statTitle="Explosiveness"
                    statIcon={<MetricsExplosivenessDistance width="16px" height="16px" style={{ fontSize: 32}} />}
                    summary={<Stack direction="row"
                      id="sprintSummary"
                      spacing={1} alignItems="flex-end"
                      justifyContent="flex-start" style={{ paddingLeft: "20px" }}>
                        <Typography variant="h6" style={{ fontSize: "14px", color: "#8C8C8C"}}>Total Distance:</Typography>
                        <Typography variant="h6" style={{ fontStyle: "italic", lineHeight: 1}}>{expDistSum.toFixed(0)}</Typography>
                        <Typography variant="h6" style={{ fontSize: "14px", color: "#8C8C8C"}}>m</Typography>
                        <span style={{width: "20px"}}></span>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>Total Count:</Typography>
                        <Typography variant="h6" style={{ fontStyle: "italic", lineHeight: 1}}>{expCnt}</Typography>
                        <Typography variant="h6" style={{ fontSize: "12px", color: "#8C8C8C"}}>burst(s)</Typography>
                    </Stack>} />
                  <AtomicMetricsBars
                    chartInterval={isDayInterval ? ChartInterval.DAY : ChartInterval.WEEK}
                    dataSet={dataSet}
                    dataKeys={[ {dataKey: "expl", label: "Explosiveness"} ]}
                    measure="M"
                   />
                </Stack>
              </>
            }
            <HeadlineTitle title="BALL" />
            <>
              <Stack id="ballKicks" direction="column">
                <StatTitle statTitle="Ball Speed"
                  statIcon={<MetricsBallSpeed width="16px" height="16px" style={{ fontSize: 32}} />}
                  />
                <AtomicMetricsBars
                    chartInterval={isDayInterval ? ChartInterval.DAY : ChartInterval.WEEK}
                    dataSet={dataSet}
                    dataKeys={[ {dataKey: "kickSpeed", label: "Ball Speed"} ]}
                    measure="KM/H"
                   />
              </Stack>
              <Stack id="ballZones" direction="column">
                <StatTitle statTitle="Ball Speed Zones"
                  statIcon={<MetricsBallSpeed width="16px" height="16px" style={{ fontSize: 32}} />}
                  />
                  <SpeedZoneHorizontalLineChart
                    title="Total count"
                    data={ballSpeedZones}
                    units={undefined}
                    ballZones={true}
                  />
              </Stack>
            </>
          </Stack>
        </>
      )}
    </>
  );
}
