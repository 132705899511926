import { Stack, Typography } from "@mui/material";

export type StatTitleProps = {
  statTitle: string;
  statIcon: JSX.Element;
  summary?: JSX.Element;
}

export const StatTitle = (props: StatTitleProps) => {
  return <><Stack direction="row"
    spacing={1}
    justifyContent="flex-start"
    style={{ marginBottom: props.summary ? 10 : 0 }}
    >
      {props.statIcon}
      <Typography variant="h6" style={{ fontStyle: "italic"}}>{props.statTitle}</Typography>
  </Stack>
  {props.summary}</>;
};
