
export const unitFormatters = {
  meter: (value: number) =>
    value
      ? value > 1000
        ? `${(value / 1000).toFixed(1)} KM`
        : `${value.toFixed(1)} M`
      : "0 M",
};

export const barUnitFormatters = {
  meter: (value: number) =>
    value
      ? value > 1000
        ? `${(value / 1000).toFixed(2)} KM`
        : `${value.toFixed(1)} M`
      : "",
};

export type SpeedZoneData = {
  zone: string;
  value: number;
};

export type PlayerSpeedZoneData = {
  playerId: string;
  zones: SpeedZoneData[];
};
