import { LineChart } from "@mui/x-charts/LineChart";
import { ActiveChartColors, axisXTickFormat, showMinuteTick, TICK_INTERVAL } from "./style-constants";
import { ChartsXAxis, ChartsYAxis, LineSeriesType } from "@mui/x-charts";
import { ChartInterval, StatDef } from "./chart-types";

export interface MotionSpeedLinesProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval;
  measure?: string;
  isLoading?: boolean;
}

export const MotionSpeedLines = (props: MotionSpeedLinesProps) => {
  
  const series = new Array<LineSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "line",
      dataKey: stat.dataKey,
      label: stat.label,
      curve: "linear",
      valueFormatter: (val) => val ? val.toString() : "0",
      color: ActiveChartColors[props.dataKeys.indexOf(stat)]
    });
  });

  return <LineChart
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    loading={props.isLoading}
    dataset={props.dataSet}
    xAxis = {[
      { dataKey: "xLabel", scaleType: "band", id: "x-axis-id", disableTicks: true,
        valueFormatter: axisXTickFormat,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval)
      }
    ]}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true }
    ]}
    tooltip={{ trigger:"axis" }}
    grid={{ horizontal: true }}
    axisHighlight={{ x: "band" }}
    slotProps={{
        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 10
        }}}
    >
    <ChartsXAxis position="bottom" axisId="x-axis-id" />
    <ChartsYAxis label={props.measure ?? "KM/H"} position="left" labelStyle={{verticalAlign: "bottom"}} axisId="meters" />
  </LineChart>;
};
