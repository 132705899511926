import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as React from "react";
import { queryClient, useAuthMutation, useAuthQuery } from "providers/auth";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { InviteStatus, NotAttendReason, ParticipationStatus, SyncStatus } from "@gamer/common/lib/models/events";

import { PlayerAvatar } from "components/avatar.component";
import theme, { muiTheme } from "../../theme";
import { toast } from "react-toastify";
import {
  ITeamMemberInfo,
  PlayerPosition,
  TeamMemberRole,
} from "store/team-members/team-members.model";
import { NavigationContext } from "providers/navigation.provider";
import {
  getTeamEventById,
  updateUserEvent,
  updateUserPositionInEvent,
} from "store/events/events.query";
import { PositionSelectModal } from "containers/position/position-select.modal";
import { PositionButton } from "containers/position/position.button";
import { TeamEventUserSpecific } from "store/events/events.model";
import TooltipCustom from "components/tooltip.component";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import SyncIcon from "@mui/icons-material/Sync";
import { Skeleton, Button } from "@mui/material";
import { getName } from "utils/team";
import { getTeamMembers } from "store/team-members/team-members.query";
import NotSynced from "components/icons/NotSynced";
import NoFeedback from "components/icons/NoFeedback";

export type TimelineEventParticipationListProps = TeamEventUserSpecific;
enum AttendyCategory {
  PARTICIPATED,
  NOINFO,
  NOTATTENDED
};

export type TimelineEventMemberPositionProps = {
  playerName: string;
  inviteStatus?: InviteStatus;
  participationStatus?: ParticipationStatus;
  eventPosition?: PlayerPosition;
  notAttendReason?: NotAttendReason;
  attendCategory: AttendyCategory;
  syncStatus?: SyncStatus;
  feedbackAvailable?: boolean;
  member: ITeamMemberInfo;
  rowIndex: number;
  onPlayerPositionClick: (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => void;
};

export const EventAttendeesInviteStatus = ({
  inviteStatus,
}: {
  inviteStatus?: InviteStatus;
}) => (
  <TooltipCustom title={inviteStatus?.replace("_", " ")} arrow placement="top">
    <Typography variant="h6">
      {inviteStatus === InviteStatus.ACCEPTED ? <DoneIcon /> : ""}
      {inviteStatus === InviteStatus.REJECTED ? <CloseIcon /> : ""}
      {inviteStatus === InviteStatus.TENTATIVE ? <HelpOutlineIcon /> : ""}
      {inviteStatus === InviteStatus.INVITATION_PENDING ? <SendIcon /> : ""}
    </Typography>
  </TooltipCustom>
);

export const EventAttendeesSyncStatus = ({
  syncStatus,
}: {
  syncStatus?: SyncStatus;
}) => (
  <TooltipCustom title={syncStatus?.replace("_", " ") || "Sync status unknown"} arrow placement="top">
    <Typography variant="h6"
      style={{ textTransform: "lowercase", fontSize: 14, color: "#B3B3B3", verticalAlign: "center" }}>
      {syncStatus === SyncStatus.EMPTY_BATTERY ? <>battery empty<NotSynced /></> : ""}
      {syncStatus === SyncStatus.OTHER ? <>other reason<NotSynced /></> : ""}
      {syncStatus === SyncStatus.SYNCED ? "" : ""}
      {syncStatus === SyncStatus.SYNC_ISSUE ? <>couldn't sync<NotSynced /></> : ""}
      {syncStatus === SyncStatus.TAG_BROKEN ? <>tag broken<NotSynced /></> : ""}
      {syncStatus === SyncStatus.TAG_NOT_USED ? <>didn't were tag<NotSynced /></> : ""}
      {syncStatus === undefined ? <>sync status uknown <NotSynced /></> : ""}
    </Typography>
  </TooltipCustom>
);

export const EventAttendeesNotAttend = ({
  notAttendReason,
}: {
  notAttendReason?: NotAttendReason;
}) => (
  <TooltipCustom title={notAttendReason?.replace("_", " ")} arrow placement="top">
    <Typography variant="h6" style={{ textTransform: "lowercase", fontSize: 14, color: "#B3B3B3" }}>
      {notAttendReason === NotAttendReason.CONFLICT ? "time conflict" : ""}
      {notAttendReason === NotAttendReason.INJURED ? "injured" : ""}
      {notAttendReason === NotAttendReason.OTHER ? "other reason" : ""}
      {notAttendReason === NotAttendReason.SICK ? "sick" : ""}
    </Typography>
  </TooltipCustom>
);

export const EventAttendeesParticipationStatus = ({
  participationStatus,
}: {
  participationStatus?: ParticipationStatus;
}) => (
  <TooltipCustom
    title={participationStatus?.replace("_", " ")}
    arrow
    placement="top"
  >
    <Typography variant="h6">
      {participationStatus === ParticipationStatus.MISSED ? (
        <CallMissedIcon />
      ) : (
        ""
      )}
      {participationStatus === ParticipationStatus.NOT_SYNCED ? (
        <PauseCircleOutlineIcon />
      ) : (
        ""
      )}
      {participationStatus === ParticipationStatus.SYNCED ? <SyncIcon /> : ""}
    </Typography>
  </TooltipCustom>
);

export const TimelineEventMemberPosition = ({
  playerName,
  member,
  eventPosition,
  inviteStatus,
  syncStatus,
  attendCategory,
  notAttendReason,
  feedbackAvailable,
  onPlayerPositionClick,
  rowIndex
}: TimelineEventMemberPositionProps) => {
  const handlePositionButtonClick = (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => {
    onPlayerPositionClick(member, eventPosition);
  };
  const navigator = React.useContext(NavigationContext);
  const { currentTeam } = navigator;

  return (
    <TableRow key={member.playerId} 
      style={{ backgroundColor: rowIndex % 2 === 0 ? "#1F1F1F" : "#1A1A1A",
        borderStyle: "hidden"
       }}>
      <TableCell style={{width: 16}} ></TableCell>
      <TableCell align="left" style={{width: 64}}>
        {member && (<PlayerAvatar
          teamId={member.teamId}
          playerId={member.playerId} />)
        }
      </TableCell>
      <TableCell align="left" style={{width: 250}}>
        <Typography variant="subtitle2">{playerName}</Typography>
      </TableCell>
      <TableCell align="left">
        <PositionButton
          disabled={
            member === null ||
            currentTeam.role === TeamMemberRole.PLAYER
          }
          position={eventPosition}
          onClick={() =>
            handlePositionButtonClick(member, eventPosition)
          }
        />
      </TableCell>
      <TableCell align="right">
        <Grid container spacing={1} justifyContent="flex-end">
          {attendCategory === AttendyCategory.NOTATTENDED && <Grid item id="playerInviteStatus">
            <EventAttendeesNotAttend notAttendReason={notAttendReason} />
          </Grid>}
          {attendCategory !== AttendyCategory.NOTATTENDED && <Grid item id="playerSyncStatus">
            <EventAttendeesSyncStatus syncStatus={syncStatus} />
          </Grid>}
          {attendCategory !== AttendyCategory.NOTATTENDED && <Grid item id="playerParticipationStatus">
            <TooltipCustom title="No Feedback Provided" arrow placement="top">
              <Typography variant="h6" style={{ textTransform: "lowercase", fontSize: 14, color: "#B3B3B3" }}>
                {!feedbackAvailable ? <NoFeedback /> : ""}
              </Typography>
            </TooltipCustom>
          </Grid>}
          <Grid item id="playerInviteStatus">
            <EventAttendeesInviteStatus inviteStatus={inviteStatus} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export const EventAttendeesTab = ({
  teamId,
  eventId,
  ...event
}: TimelineEventParticipationListProps) => {
  const analytics = useAnalytics();
  const eventQuery = useAuthQuery(
    ["event", eventId],
    getTeamEventById(eventId),
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
  );
  const userEventMutation = useAuthMutation(
    ["updateUserEvent", eventId, event.participation.playerId],
    updateUserEvent(eventId, event.participation.playerId),
  );
  const [positionModalSelectedPlayer, setPositionModalSelectedPlayer] =
    React.useState<ITeamMemberInfo | null>(null);
  const [positionModalEventPosition, setPositionModalEventPosition] =
    React.useState<PlayerPosition | undefined>(undefined);
  const positionMutation = useAuthMutation(
    ["event", eventId],
    updateUserPositionInEvent(eventId),
  );

  React.useEffect(() => {
    if (positionMutation.isSuccess || userEventMutation.isSuccess) {
      queryClient.resetQueries(["event", eventId]);
    }
  }, [positionMutation.isSuccess, userEventMutation.isSuccess, eventId]);

  if (teamMembersQuery.isLoading || eventQuery.isLoading) {
    return <CircularProgress />;
  }

  const members = teamMembersQuery.data?.teamMembers.reduce(
    (acc, member) => ({ ...acc, [member.playerId]: member }),
    {} as Record<string, ITeamMemberInfo>,
  );

  const changePlayerPosition = async (
    playerId: string,
    position: PlayerPosition,
  ) => {
    await positionMutation.mutateAsync({ playerId, data: { position } });

    if (positionMutation.isError) {
      return toast.error("Failed to set member position");
    }

    toast.success("Position changed!");
  };

  const handlePositionModalClose = (position?: PlayerPosition) => {
    if (positionModalSelectedPlayer?.playerId && position) {
      changePlayerPosition(positionModalSelectedPlayer?.playerId, position);
    }
    setPositionModalSelectedPlayer(null);
  };

  const handlePositionButtonClick = (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => {
    setPositionModalSelectedPlayer(member);
    setPositionModalEventPosition(eventPosition);
    analytics.track("event-position-change", {
      eventId,
      playerId: member.playerId,
      eventPosition,
    });
  };

  const onInviteStatusChange = (inviteStatus: InviteStatus) => () => {
    userEventMutation.mutate({ inviteStatus });
    analytics.track("event-invite-status-change", { eventId, inviteStatus });
  };

  const allPlayers = members ? eventQuery.data?.participants
    .filter(
      ({ playerId }) =>
        members[playerId] && members[playerId].role === "PLAYER",
    )
    .sort(({ playerId: a }, { playerId: b }) =>
      members[a]?.memberInfo?.firstName &&
        members[b]?.memberInfo?.firstName
        ? members[a]!.memberInfo!.firstName!.localeCompare(
          members[b]!.memberInfo!.firstName!,
        )
        : -1,
    ) : [];
  const notAttended = allPlayers?.filter((plr) =>
    plr.inviteStatus === InviteStatus.REJECTED || plr.notAttendReason);
  const noInfo = allPlayers?.filter((plr) =>
    plr.inviteStatus !== InviteStatus.REJECTED && !plr.notAttendReason && !plr.syncStatus && !plr.feedback);
  const participated = allPlayers?.filter((plr) =>
    !notAttended?.find((na) => na.playerId === plr.playerId) &&
    !noInfo?.find((na) => na.playerId === plr.playerId));

  if (members) {
    return (
      <Grid container direction="column">
        <Grid item id="manageParticipationGridItem">
          <Paper
            hidden={event.endTime < Date.now()}
            style={{ padding: 15, paddingLeft: 30 }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="subtitle1">
                  <Trans id="timeline_attendees_manage_participation_title">
                    Manage your participation in this event.
                  </Trans>
                </Typography>
              </Grid>
              <Grid item>
                {userEventMutation.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item id="acceptEventBtn"
                      hidden={
                        event.participation.inviteStatus ===
                        InviteStatus.ACCEPTED
                      }
                    >
                      <Button
                        variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        color="primary"
                        onClick={onInviteStatusChange(InviteStatus.ACCEPTED)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <DoneIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_accept_cta"
                                context="rsvp"
                              >
                                ACCEPT
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item id="rejectEventBtn"
                      hidden={
                        event.participation.inviteStatus ===
                        InviteStatus.REJECTED
                      }
                    >
                      <Button
                        variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        onClick={onInviteStatusChange(InviteStatus.REJECTED)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <CloseIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_reject_cta"
                                context="rsvp"
                              >
                                REJECT
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item id="tentativeEventBtn"
                      hidden={[
                        InviteStatus.TENTATIVE,
                        InviteStatus.INVITATION_PENDING,
                      ].includes(event.participation.inviteStatus || InviteStatus.ACCEPTED)}
                    >
                      <Button
                        variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        onClick={onInviteStatusChange(InviteStatus.TENTATIVE)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <HelpOutlineIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_tentative_cta"
                                context="rsvp"
                              >
                                TENTATIVE
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item id="attendencyList">
          {userEventMutation.isLoading || positionMutation.isLoading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            <Stack direction="column" spacing={1}>
              <Accordion defaultExpanded={(participated?.length || 0) > 0} id="participatedAccordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  style={{ backgroundColor: theme.background.primary }}>
                  <Typography variant="h6">
                    Participated ({participated?.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: theme.background.primary }}>
                  <TableContainer component={Paper} style={{ backgroundColor: theme.background.primary }}>
                    <Table size="small" aria-label="participated"
                      style={{
                        borderWidth: "1px",
                        borderColor: "#262626",
                        borderRadius: "8px",
                        borderStyle: "solid",
                        backgroundColor: "#262626"
                      }}>
                      <TableHead style={{ backgroundColor: "#0D0D0D", height: 80 }}>
                        <TableRow>
                          <TableCell width="16px" align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Position
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participated && participated.map(
                          ({ playerId, position, inviteStatus, notAttendReason, feedback, syncStatus, participationStatus }, ind) => (
                            <TimelineEventMemberPosition
                              attendCategory={AttendyCategory.PARTICIPATED}
                              key={playerId}
                              member={members[playerId]}
                              eventPosition={position}
                              inviteStatus={inviteStatus}
                              notAttendReason={notAttendReason}
                              syncStatus={syncStatus}
                              feedbackAvailable={feedback !== undefined}
                              participationStatus={participationStatus}
                              playerName={getName(members[playerId])}
                              onPlayerPositionClick={handlePositionButtonClick}
                              rowIndex={ind}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={(noInfo?.length || 0) > 0} id="noInfoAccordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  style={{ backgroundColor: theme.background.primary }}>
                  <Typography variant="h6">
                    No Information ({noInfo?.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: theme.background.primary }}>
                  <TableContainer component={Paper} style={{ backgroundColor: theme.background.primary }}>
                    <Table size="small" aria-label="participated"
                      style={{
                        borderWidth: "1px",
                        borderColor: "#262626",
                        borderRadius: "8px",
                        borderStyle: "solid",
                        backgroundColor: "#262626"
                      }}>
                      <TableHead style={{ backgroundColor: "#0D0D0D", height: 80 }}>
                        <TableRow>
                          <TableCell width="16px" align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Position
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {noInfo && noInfo.map(
                          ({ playerId, position, inviteStatus, notAttendReason, feedback, syncStatus, participationStatus }, ind) => (
                            <TimelineEventMemberPosition
                              attendCategory={AttendyCategory.NOINFO}
                              key={playerId}
                              member={members[playerId]}
                              eventPosition={position}
                              notAttendReason={notAttendReason}
                              syncStatus={syncStatus}
                              inviteStatus={inviteStatus}
                              feedbackAvailable={feedback !== undefined}
                              participationStatus={participationStatus}
                              playerName={getName(members[playerId])}
                              onPlayerPositionClick={handlePositionButtonClick}
                              rowIndex={ind}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={(notAttended?.length || 0) > 0} id="notAttendAccordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  style={{ backgroundColor: theme.background.primary }}>
                  <Typography variant="h6">Not Attended ({notAttended?.length})</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: theme.background.primary }}>
                  <TableContainer component={Paper} style={{ backgroundColor: theme.background.primary }}>
                    <Table size="small" aria-label="participated"
                      style={{
                        borderWidth: "1px",
                        borderColor: "#262626",
                        borderRadius: "8px",
                        borderStyle: "solid",
                        backgroundColor: "#262626"
                      }}>
                      <TableHead style={{ backgroundColor: "#0D0D0D", height: 80 }}>
                        <TableRow>
                          <TableCell width="16px" align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="h6" style={{ fontSize: "16px" }}>
                              Position
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notAttended && notAttended.map(
                          ({ playerId, position, inviteStatus, notAttendReason, syncStatus, participationStatus }, ind) => (
                            <TimelineEventMemberPosition
                              attendCategory={AttendyCategory.NOTATTENDED}
                              key={playerId}
                              member={members[playerId]}
                              eventPosition={position}
                              inviteStatus={inviteStatus}
                              notAttendReason={notAttendReason}
                              syncStatus={syncStatus}
                              participationStatus={participationStatus}
                              playerName={getName(members[playerId])}
                              onPlayerPositionClick={handlePositionButtonClick}
                              rowIndex={ind}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Stack>
          )}
        </Grid>
        {positionModalSelectedPlayer && (
          <PositionSelectModal
            open={Boolean(positionModalSelectedPlayer)}
            handleClose={handlePositionModalClose}
            position={positionModalEventPosition}
            preferredPosition={
              positionModalSelectedPlayer?.memberInfo?.position
            }
            playerName={`${getName(positionModalSelectedPlayer)}`}
          />
        )}
      </Grid>
    );
  }

  return <ErrorIcon />;
};
