import { Avatar, Grid, Typography } from "@mui/material";
import { RUN_BARS_COLORS } from "./line-speedzone-line";
import { BarChart } from "@mui/x-charts";
import { font } from "theme";
import { PlayerSpeedZoneData, unitFormatters } from "./utils";
import { animated, to } from "@react-spring/web";

type BarSpeedZoneChartProps = {
  title: string;
  units: "meter" | undefined;
  data: Array<PlayerSpeedZoneData>;
  avatar?: (playerId: string) => React.ReactNode;
};

const MAX_GRAPH_HEIGHT = 251;

export const BarSpeedZoneChart = (props: BarSpeedZoneChartProps) => {
  const zones = Array.from(
    new Set(props.data.map((z) => z.zones.map((d) => d.zone)).flat()),
  );
  const playerIds = props.data.map((d) => d.playerId);
  const totals = props.data.map((d) => ({
    playerId: d.playerId,
    total: d.zones.reduce((acc, z) => acc + z.value, 0),
  }));
  const data = props.data.map((d) =>
    zones.map((zone) => d.zones.find((z) => z.zone === zone)?.value || 0),
  );

  const formatValue =
    unitFormatters[props.units as any] || ((v: number) => v.toFixed(2));

  return (
    <Grid container direction="row" alignItems="flex-end" width="100%">
      <Grid item minWidth={900} width="85%">
        <Grid container direction="column">
          <Grid item>
            <BarChart
              height={251}
              grid={{
                horizontal: true
              }}
              barLabel={(v) => String(props.units === "meter" ? v.value?.toFixed(2) : v.value?.toFixed(0))}
              // bottomAxis={{
              //   slots: {
              //     axisTickLabel: ({ style, children, text }) => {
              //       console.log(style);
              //       return (
              //         <>
              //           {playerIds.map((playerId) => {
              //             return (
              //               <animated.view style={{ fill: "white" }}>
              //                 {props.avatar ? (
              //                   props.avatar(playerId)
              //                 ) : (
              //                     playerId
              //                 )}
              //               </animated.view>
              //             );
              //           })}
              //           <animated.text
              //             style={{
              //               fill: "white",
              //             }}
              //           >
              //             {text}
              //           </animated.text>
              //         </>
              //       );
              //     },
              //   },
              // }}
              bottomAxis={null}
              xAxis={[
                {
                  scaleType: "band",
                  data: zones,
                  colorMap: {
                    type: "ordinal",
                    values: zones,
                    colors: RUN_BARS_COLORS,
                  },
                },
              ]}
              series={data.map((data) => ({
                data,
                type: "bar",
              }))}
              slots={{
                barLabel: ({ style, children }) => {
                  return (
                    <>
                      <animated.text
                        style={{
                          ...style,
                          fill: "white",
                          y: to(
                            [(style as any).y],
                            (y) => y - (MAX_GRAPH_HEIGHT * 0.83 - y),
                          ),
                          textAnchor: "middle",
                        }}
                      >
                        {children}
                      </animated.text>
                    </>
                  );
                },
              }}
              slotProps={{
                bar: {
                  width: 16,
                },
                barLabel: {
                  style: {
                    textAlign: "center",
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontFamily: font.adineue.cond,
                    fontSize: "17px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "1.2px",
                    textTransform: "uppercase",
                    position: "absolute",
                    transform: "translateX(5px)"
                  },
                },
                legend: {
                  itemGap: 20,
                  markGap: 5,
                  padding: {
                    top: 60,
                  },
                  labelStyle: {
                    fontFamily: font.adihaus.regular,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "14px",
                  },
                  position: {
                    vertical: "bottom",
                    horizontal: "middle",
                  },
                },
              }}
            />
          </Grid>
          <Grid item style={{ position: "relative", top: "-30px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              paddingLeft="30px"
              paddingRight="30px"
            >
              {zones.map((zone) => (
                <Grid item minWidth={120}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item minWidth={120}>
                      <Grid
                        container
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        {playerIds.map((playerId) => (
                          <Grid item>
                            {props.avatar ? (
                              props.avatar(playerId)
                            ) : (
                              <Avatar
                                variant="circular"
                                style={{ width: 24, height: 24 }}
                              >
                                {playerId}
                              </Avatar>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        fontSize={14}
                        style={{ textTransform: "capitalize" }}
                      >
                        {zone.split("_").join(" ")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          {totals.map(({ playerId, total }) => (
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="space-around"
              >
                <Grid item>
                  {props.avatar ? (
                    props.avatar(playerId)
                  ) : (
                    <Avatar
                      variant="circular"
                      style={{ width: 24, height: 24 }}
                    >
                      {playerId}
                    </Avatar>
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      /* styleName: Web/Headlines/HL Small; */
                      fontFamily: font.adihaus.regular,
                      fontSize: "24px",
                      fontStyle: "italic",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "1px",
                      textAlign: "left",
                    }}
                  >
                    {formatValue(total)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item height="50px" marginTop="50px" paddingBottom="50px">
            <Typography variant="body1" fontSize={14}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
