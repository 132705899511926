import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { font } from "theme";
import { barUnitFormatters, SpeedZoneData, unitFormatters } from "./utils";

export const RUN_BARS_COLORS = [
  "#7AA7FF",
  "#26488A",
  "#6C9222",
  "#AD7C00",
  "#6D2A2A",
];

export const BALL_BARS_COLORS = [
  "#26488A",
  "#6C9222",
  "#AD7C00",
  "#6D2A2A",
];

export const SpeedZoneHorizontalLineChart = (props: {
  data: SpeedZoneData[];
  title: string;
  units: "meter" | undefined;
  ballZones?: boolean
}) => {
  const total = props.data.reduce((acc, d) => acc + d.value, 0);
  const formatValue =
    unitFormatters[props.units as any] || ((v: number) => v.toString());
  const formatBarValue =
    barUnitFormatters[props.units as any] || ((v: number) => v.toString());

  return (
    <Grid container direction="row">
      <Grid item xs={11}>
        <BarChart
          height={184}
          series={[
            ...props.data.map((d, index) => ({
              type: "bar" as const,
              data: [d.value],
              label: d.zone
                .replaceAll("_", " ")
                .toLowerCase()
                .replace(/\b\w/g, (c) => c.toUpperCase()),
              stack: "Zone",
              color: props.ballZones ? BALL_BARS_COLORS[index] : RUN_BARS_COLORS[index],
            })),
          ]}
          xAxis={[
            {
              valueFormatter: (value) => formatValue(value),
              max: total,
            },
          ]}
          layout="horizontal"
          barLabel={(v) => formatBarValue(v.value)}
          leftAxis={null}
          bottomAxis={{
            disableLine: true,
            tickSize: 0,
            tickLabelInterval: (_v, i) => i % 2 === 0,
            tickLabelStyle: {
              color: "var(--Greys-grey_45, var(--GREY-045, #8C8C8C))",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: font.adineue.cond,
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "8px",
              letterSpacing: "1.2px",
              textTransform: "uppercase",
            },
          }}
          tooltip={{
            trigger: "item" 
          }}
          grid={{
            vertical: true,
          }}
          slotProps={{
            barLabel: {
              style: {
                color: "var(--Greys-grey_10, var(--GREY-010, #E5E5E5))",
                textAlign: "center",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: font.adineue.cond,
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "1.2px",
                textTransform: "uppercase",
                position: "absolute",
                transform: "translateY(-30px) translateX(10px)",
              },
            },
            bar: {
              style: {
                height: "36px",
                transform: "translateY(15px)"
              },
            },
            legend: {
              itemGap: 20,
              markGap: 5,
              classes: {
                mark: "{rx:0px}"
              },
              padding: {
                top: 60,
              },
              labelStyle: {
                fontFamily: font.adihaus.regular,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "14px",
              },
              position: {
                vertical: "bottom",
                horizontal: "middle",
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Grid container direction="column" alignItems="center" spacing={4} justifyContent="center">
          <Grid item marginTop={10}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  style={{
                    fontFamily: font.adineue.cond,
                    fontSize: "24px",
                    fontStyle: "italic",
                    fontWeight: 500,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    textTransform: "uppercase",
                  }}
                >
                  {formatValue(total).split(" ")[0]}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: "#8C8C8C",
                    fontSize: 12,
                    fontFamily: "adineue PRO Cond",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    lineHeight: "8px",
                    letterSpacing: 1.2,
                    wordWrap: "break-word",
                  }}
                >
                  {formatValue(total).split(" ")[1]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginTop={2}>
            <Typography
              style={{
                height: 50,
                color: "white",
                fontSize: 14,
                fontFamily: font.adihaus.regular ,
                fontWeight: "400"
              }}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
